import React from 'react';
import Cookies from 'js-cookie';
import ErrorNotFound from '../../components/errors/ErrorNotFound';

// Higher-Order Component to handle redirection based on authentication
const RedirectIfAuthenticated = ({ children }) => {
    const UserToken = Cookies.get('UserToken');
    const adminToken = Cookies.get('adminToken');

    if (UserToken || adminToken) {
        return <ErrorNotFound/>;
    }

    return children;
};

export default RedirectIfAuthenticated;
