import facebook_icon from '../assets/icons/facebook.svg'
import linkedin_icon from '../assets/icons/linkedin.svg'
import youtube_icon from '../assets/icons/youtube.svg'
import instagram_icon from '../assets/icons/instagram.svg'
/*
حسابات تراك ميديكل 
https://www.instagram.com/track_medical/

https://www.facebook.com/profile.php?id=61567968324793

https://x.com/TrackMedical

https://www.youtube.com/@TrackMedical

https://www.snapchat.com/add/track_medical?share_id=kTqCrHOyEIc&locale=en-US

https://www.linkedin.com/company/105421334/admin/dashboard/


*/
function Socials() {
    const socials = {
        instagram: 'https://www.instagram.com/track_medical/',
        facebook: 'https://www.facebook.com/profile.php?id=61567968324793',
        twitter: 'https://x.com/TrackMedical',
        youtube: 'https://www.youtube.com/@TrackMedical',
        snapchat: 'https://www.snapchat.com/add/track_medical?share_id=kTqCrHOyEIc&locale=en-US',
        linkedin: 'https://www.linkedin.com/company/105421334/admin/dashboard/'
    }
    return ( 
        <ul className='grid grid-cols-4 gap-3 w-fit items-center justify-start m-auto'>
            <li><a href={socials.facebook}><img className='w-[20px]' src={facebook_icon} alt='facebook_icon'></img></a></li>
            <li><a href={socials.linkedin}><img className='w-[20px]' src={linkedin_icon} alt='linkedin_icon'></img></a></li>
            <li><a href={socials.youtube}><img className='w-[20px]' src={youtube_icon} alt='youtube_icon'></img></a></li>
            <li><a href={socials.instagram}><img className='w-[20px]' src={instagram_icon} alt='instagram_icon'></img></a></li>
        </ul>
     );
}

export default Socials;